import { PlanTypes, CommonTypes } from "../../../interfaces";
import { axios, sandboxAxios } from "../../../lib/axios";
import { API_ROUTES, LOCAL_CONSTANTS } from "../../../lib/constants";
import { store } from "../../../redux/store";

const getPathName = (): boolean => {
  const location = window?.location
  const getURLPath = location?.pathname?.split('/')
  const getLast = getURLPath[1];
  return (LOCAL_CONSTANTS?.LASTPATHNAME?.SANDBOX_PLANS === getLast)
}

export const tabList = (): Promise<PlanTypes.HomeTabResponse> => {
  return (!getPathName() ? axios : sandboxAxios)?.get(API_ROUTES.HOME_TABS.LIST);
}

export const updateTabList = (tabId: string, payload: PlanTypes.UpdateHomeTabPayload): Promise<CommonTypes.DefaultResponse> => {
  return (!getPathName() ? axios : sandboxAxios)?.put(`${API_ROUTES.HOME_TABS.LIST}/${tabId}`, payload);
}

export const getPlans = (query: PlanTypes.PlanListQuery): Promise<PlanTypes.PlanListResponse> => {
  return (!getPathName() ? axios : sandboxAxios)?.get(API_ROUTES.PLANS.LIST, { params: query });
}

export const updatePlan = (payload: PlanTypes.PlanUpdatePayload, planId: string): Promise<CommonTypes.DefaultResponse> => {
  return (!getPathName() ? axios : sandboxAxios)?.put(`${API_ROUTES.PLANS.UPDATE}/${planId}`, payload);
}

export const updatePlanDetails = (payload: PlanTypes.PlanDetailsUpdatePayload): Promise<CommonTypes.DefaultResponse> => {
  return (!getPathName() ? axios : sandboxAxios)?.put(`${API_ROUTES.PLANS.UPDATE}`, payload);
}

export const getCountryRegionsPlanDetails = (query: PlanTypes.CountryRegionsPlanDetailsQuery): Promise<PlanTypes.PlanDetailsResponse> => {
  return (!getPathName() ? axios : sandboxAxios)?.get(`${API_ROUTES.PLANS.DETAILS}`, { params: query });
}

export const popularCountriesUpdate = (payload: PlanTypes.UpdatePopularCountriesPayload): Promise<CommonTypes.DefaultResponse> => {
  return (!getPathName() ? axios : sandboxAxios)?.put(API_ROUTES.COUNTRIES.POPULAR_COUNTRIES_UPDATE, payload);
}

export const updatePopularCountries = (payload: any): Promise<any> => {
  return (!getPathName() ? axios : sandboxAxios)?.put(API_ROUTES.COUNTRIES.UPDATE_POPULAR_COUNTRIES, payload);
}

export const listCountries = (params: any): Promise<any> => {
	return (!getPathName() ? axios : sandboxAxios)?.get(API_ROUTES.COUNTRIES.LIST, { params });
}

export const listRegions = (): Promise<any> => {
	return (!getPathName() ? axios : sandboxAxios)?.get(API_ROUTES.REGIONS.LIST);
}