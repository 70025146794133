import { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import commonStyles from "../../../lib/common/common.module.css";
import { LOCAL_CONSTANTS } from "../../../lib/constants";
import { getNiyoApiSecretKey, niyGenerateApiSecretKey } from "../api";
import Layout from "../../../ui/layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getSandboxoApiSecretKey, sandboxGenerateApiSecretKey, sandboxLogin } from "../api/sanBoxApi";

const SecretKeyConfiguration = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { partnerName, partnerWebsiteId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<Number>(1);
  const [secretKeyShow, setSecretKeyShow] = useState<boolean>(false);
  const [sanboxTokan, setSanboxTokan] = useState<string>("");

  const [secertDetails, setSecertDetails] = useState<any>();
  const roles = window.localStorage.getItem("admin_roles")
  const routAccess = (roles !== null && LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles)) || (roles !== null && LOCAL_CONSTANTS.ROLES.VFS_USER === Number(roles) || (roles !== null && LOCAL_CONSTANTS.ROLES.TRAVELTHRU_USER === Number(roles)))
  const params = LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles) ? "niyo" : "travelthru"


  const getSecretKeyAPis = (sanboxTokan:string) => {
    LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles) ?
      getNiyoApiSecretKey().then((res) => {
        setSecertDetails({
          secretKey: res?.data?.data?.secretKey,
          apikey: res?.data?.data?.apiKey
        })
      }).catch((res: any) => {
        toast.error(res?.data?.message || "Something went wrong!")
      })
      :
      getSandboxoApiSecretKey(sanboxTokan).then((res) => {
        setSecertDetails({
          secretKey: res?.data?.data?.data?.secretKey,
          apikey: res?.data?.data?.data?.apiKey
        })
      }).catch((res: any) => {
        toast.error(res?.data?.message || "Something went wrong!")
      })
  }
  useEffect(() => {
    sandboxLogin({
      email: "travelthru@gmail.com",
      password: "travelthru@gmail.com"
    }).then((res: any) => {
      setSanboxTokan(res?.data?.accessToken)
    }).catch((error: any) => {
      toast.error(error?.data?.message || "Something went wrong!")
    })
  }, [])

  useEffect(() => {
    getSecretKeyAPis(sanboxTokan)
  }, [sanboxTokan])

  const generatSecretKey = (tabId: any) => {

    LOCAL_CONSTANTS.ROLES.NIYO_USER === Number(roles) ?
      niyGenerateApiSecretKey().then((res) => {
        setSecertDetails(res?.data?.data)
      }).catch((res: any) => {
        toast.error(res?.data?.message || "Something went wrong!")
      })
      :
      sandboxGenerateApiSecretKey(sanboxTokan).then((res) => {
        setSecertDetails(res?.data?.data?.data)
      }).catch((res: any) => {
        toast.error(res?.data?.message || "Something went wrong!")
      })
  }

  return (
    <Layout title={`Secret keys details`}>
      <div className={commonStyles.tableData}>
        <div className={styles.mainTabbing}>
          <div className="leftTabbing">
            <div className={styles.topCms}>
              <ul>
                <li
                  className={activeTab === 1 ? styles.active : ""}
                  onClick={() => {
                    setActiveTab(1)
                  }}
                >
                  <p>Secret keys</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="rightTabbing">
          </div>
        </div>
        <div className="secretkey-main">
          <div className="main-form">
            <div className="form-group">
              <label htmlFor="">Secret key : </label>
              <button onClick={generatSecretKey} >Generat</button>
            </div>
            <div className="getSecretKey">
              <div className="secretKeys">
                <label htmlFor="">Secret key:</label>
                <div className="secretkeyNew">{secretKeyShow ? secertDetails?.secretKey : "*******************************************"}</div>
                <button onClick={() => setSecretKeyShow(!secretKeyShow)}>{secretKeyShow ? "Hide" : "Show"}</button>
              </div>
              <div className="apiKeys">
                <div className="secretkey"><label htmlFor="">Api key:</label><span>{secertDetails?.apikey}</span></div>
              </div>

              <div className="apiKeys">
                <div className="secretkey"><label htmlFor="">Swagger Url:</label><a href="https://dev-partner.commbitz.com/api-partnerNiyo" target="_blank">https://dev-partner.commbitz.com/api-partnerNiyo</a></div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SecretKeyConfiguration;
